
  .form-group {
    margin-bottom: 1.5rem; /* Increased space between form groups */
  }
  
  .form-group label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: bold;
  }
  
  .form-group input,
  .form-group select,
  .form-group textarea {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .form-group textarea {
    height: 100px;
    resize: vertical;
  }
  
  .form-group button {
    padding: 0.75rem 1.5rem;
    background-color: #12bca2;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .form-group button:hover {
    background-color: #12bca2;
  }
  
  .error {
    color: red;
    margin-top: 1rem;
  }
  