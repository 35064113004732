/* Home.css */
.home-container {
    font-family: Arial, sans-serif;
    color: #333;
    /*background-color: #f9f9f9;*/
    background-color: white;
    padding: 20px;
    max-width: auto;
    margin: 0;
  }

  nav1 {
    background: white;
    padding: 10px 20px;
    text-align: center;
    font-family: 'Poppins';
  }
  
  .home-header {
    background-color: white;
    color: black;
    padding: 20px;
    text-align: center;
    max-width: auto;
  }
  
  .home-header h1 {
    margin: 0;
  }
  
  .home-content {
    padding: 20px;
  }
  
  .home-nav ul {
    background-color: white;
    margin:0;
    list-style-type: none;
    padding: 0;
  }
  
  .home-nav li {
    margin: 10px 0;
  }
  
  .home-nav a {
    color: black;
    text-decoration: none;
    font-size: 18px;
  }
  
  .home-nav a:hover {
    text-decoration: underline;
  }

 /* Home.css */

html, body {
    height: 100%;
    margin: 0;
    padding: 0;
  }
  
  .home-container {
    height: 100vh;
    display: flex;
    flex-direction: column;
  }
  