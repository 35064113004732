/* Adjust the path according to your project structure */
/* index.css */

.querie-grid {
    display: grid;
    gap: 20px; /* Adjust the space between items as needed */
  }
  
  .querie-grid p {
    margin: 0; /* Remove any default margin for paragraph */
  }
  
  .page {
    padding: 20px; /* Add some padding around the page content */
  }
  
  .page input {
    margin-bottom: 20px; /* Add space between input and grid */
  }
  