@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;800&family=VT323&display=swap');

:root {
  --primary: #12bca2;
  --secondary: #6d15df;
}

h1, h2, h3 {
  margin: 0;
}

body {
  margin: 0;
  color: #333;
  font-family: 'Poppins', sans-serif;
  background: #f2f2f2;
}

nav {
  background: var(--primary);
  padding: 10px 20px;
  text-align: center;
}

nav a {
  color: #f2f2f2;
  margin: 10px;
  display: inline-block;
}

nav h1 {
  color: #fff;
}

.page {
  max-width: 1200px;
  margin: 20px auto;
  padding: 20px;
}

/* smoothie grid */
.smoothie-grid {
  margin-top: 40px;
  display: grid;
  grid-template-columns: 1fr; /* Single column layout */
  gap: 40px;
}

.smoothie-card {
  width: 100%;
  padding: 10px;
  background: #fff;
  box-sizing: border-box;
  border-radius: 6px;
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.smoothie-card .rating {
  position: absolute;
  top: -10px;
  right: -10px;
  background: var(--secondary);
  color: #fff;
  border-radius: 6px;
  width: 40px;
  height: 40px;
  padding: 10px 0;
  line-height: 20px;
  text-align: center;
}

.smoothie-card .buttons {
  text-align: right;
}

.view-details-button {
  background: var(--primary);
  color: #fff;
  border: none;
  padding: 8px 16px;
  border-radius: 6px;
  font-family: "Poppins", sans-serif;
  cursor: pointer;
  font-size: 0.9em;
  margin-top: 10px; /* Add spacing from content */
}

.view-details-button:hover {
  background: #0f9b8e;
}

/* forms */
form {
  background: #fff;
  padding: 20px;
  max-width: 480px;
  margin: 0 auto;
  border-radius: 6px;
}

form input, form textarea {
  display: block;
  width: 100%;
  padding: 6px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  margin: 10px 0 20px 0;
}

form button {
  background: var(--primary);
  color: #fff;
  border: 0;
  border-radius: 6px;
  padding: 6px 8px;
  font-family: "Poppins", sans-serif;
  cursor: pointer;
}

/* order-by buttons */
.order-by button {
  margin-right: 10px;
  background: var(--primary);
  color: #fff;
  border: 0;
  padding: 6px 12px;
  border-radius: 6px;
  font-family: "Poppins", sans-serif;
  cursor: pointer;
}

.order-by button:hover {
  background: #0f9b8e;
}

.container {
  display: flex;
  flex-direction: column;
}
